import React, { useState, useEffect } from "react";

const DetailTapMenu = ({ getTapMenu }) => {
  const [menu, setMenu] = useState(0);

  const onChangeMenu = (menuNum) => {
    setMenu(menuNum);
  };

  useEffect(() => {
    getTapMenu(menu);
  });

  return (
    <div className="listing-detail__scroll-menu-box scroller-menu">
      <ul className="listing-detail__scroll-menu listing-detail__menu-top-border navigate-section">
        <li>
          <a
            className={menu === 0 ? "active" : ""}
            onClick={() => onChangeMenu(0)}
          >
            상세 정보
          </a>
        </li>
        <li>
          <a
            className={menu === 1 ? "active" : ""}
            onClick={() => onChangeMenu(1)}
          >
            위치정보
          </a>
        </li>
        <li>
          <a
            className={menu === 2 ? "active" : ""}
            onClick={() => onChangeMenu(2)}
          >
            블로그/리뷰
          </a>
        </li>
        <li>
          <a
            className={menu === 3 ? "active" : ""}
            onClick={() => onChangeMenu(3)}
          >
            영상보기
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DetailTapMenu;
